<script lang="ts">
import { type SlotsType, vShow, Transition } from 'vue'

export type BaseFullscreenBackgroundProps<T> = {
    modelValue?: boolean
    isVisible?: boolean | undefined
}

type BaseFullscreenBackgroundSlots<T> = {}

type ComponentOptions = {}

export function defineComponentBaseFullscreenBackground<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseFullscreenBackgroundProps<T>, BaseFullscreenBackgroundSlots<T>>) {
    return defineComponent(
        (props: BaseFullscreenBackgroundProps<T>, ctx) => {

            const isShown = computed<boolean>(() => props.isVisible !== undefined
                ? props.isVisible : !!props.modelValue
            )

            return () => h(Transition, { appear: true }, () => [
                withDirectives(h('div', {
                    class: 'sim-bg',
                    onClick: () => ctx.emit('update:modelValue', false),
                }, []), [
                    [vShow, isShown.value],
                ]),
            ])
        },
        {
            props: {
                modelValue: {
                    type: Boolean as PropType<BaseFullscreenBackgroundProps<T>['modelValue']>,
                    default: options?.props?.modelValue?.default ?? false,
                    required: options?.props?.modelValue?.required ?? false,
                },
                isVisible: {
                    type: Boolean as PropType<BaseFullscreenBackgroundProps<T>['isVisible']>,
                    default: options?.props?.isVisible?.default ?? undefined,
                    required: options?.props?.isVisible?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseFullscreenBackgroundSlots<T>>,
            emits: {
                'update:modelValue': (val: boolean) => true,
            },
        }
    )
}

export default defineComponentBaseFullscreenBackground()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseFullscreenBackground.scss" as *;

</style>
